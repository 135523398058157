import { config } from "@/lib/config"
import calcPageCount from "@/lib/utils/calcPageCount"
import { Fragment, useEffect, useState } from "react"
import Popup from "reactjs-popup"
import PopupMenuOptionLink from "../../templateux/popup-menu-option-link"
import SvgJsx from "../../templateux/svg/svg-jsx"
import { textProcess } from "@/lib/utils/textprocess"

function PostMenuMobile({
   tabarray
  ,clickval
  ,set_clickval
}) {

  const [open,set_open] = useState(false)

  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])


    const PopupTrigger = <div 
                                  onClick={()=>set_open(true)}
                                  className={`
                                    group cursor-pointer font-bold text-md flex items-center content-center border border-transparent px-1 py-1 rounded-md
                                    text-sm
                                    
                                   bg-gray-700 hover:bg-gray-600 hover:text-gray-100 text-gray-200 `
                                  }
                                  > 
                            
                                   
                                    <>
                                      <div className="flex-0 inline-block ml-2 flex-shrink-0  ">
                                        {tabarray.filter((e)=>e?.value?.toLowerCase()==clickval?.toLowerCase())[0]?.text}
                                      </div>
                                      
                                      <div 
                                        className={`  font-bold cursor-pointer ml-2 mt-1 
                                        ` }          
                                      >
                                        <div className=" 
                                                     text-gray-500  group-hover:text-gray-400
                                                     "
                                        >
                                          <SvgJsx 
                                            type='fill'
                                            icon={'chevron-down-sm'}
                                            className={`  h-6 w-6 `}
                                            title={'open'}
                                          />
                                        </div>
                                      </div>
                                    </>
                               
                                    
                                </div>

return (
    <div className="menu flex items-center content-center  flex-0 z-50">
    {!isMounted &&
    PopupTrigger
    }
    {isMounted &&
    <>
    <Popup
      trigger={PopupTrigger}
      position="bottom left"
      //on="click"
      open={open}
      onOpen={()=>set_open(true)}
      //onClose={()=>set_open('')}
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1000, width: '95%', marginTop: '8px' }}
      arrow={false}
      nested
      //ref={isMounted ? ref : null}
    >
      <div className="block sm:hidden menu ring-0 w-full xs:w-64">
        <div className="menu flex flex-col gap-y-1 rounded-b-md shadow-md p-2 -ml-4
                       bg-gray-800 
                      border  border-gray-700"
        >



        {tabarray.filter(e=>e.value!==clickval)?.map((e,index) => 
          <Fragment key={index}>
          
                    <div className="bg-gray-900 px-4 py-2 rounded-md hover:bg-gray-700 cursor-pointer text-sm"
                      onClick={()=>{
                        set_clickval(e?.value);
                        set_open(false)
                      }}
                    >
                      {textProcess(e?.text)}
                    </div>
            
            
            
          </Fragment>
          )}
          
        </div>
      </div>
    </Popup>
    </>
      }
    

  </div>
  );

 
}

export default PostMenuMobile