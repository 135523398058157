import React from 'react'
import ProfileSingle from './profile-single'

export default function ItemProfileStateContainer({
    
     itemdata
    ,userdata
    ,shrink=false
    ,ownerdata    //bol_admin_help
    ,placeholder=false
    ,navtype
    ,helptype
}) {

        return (<div className="px-4">
            <ProfileSingle
                itemdata={itemdata}
                userdata={userdata}
                shrink={shrink}
                ownerdata={ownerdata}
                owner_block_json={ownerdata?.block_json}
                owner_name={ownerdata?.user_name}
                owner_id={ownerdata?.id}
                owner_bol_staff_help={ownerdata?.bol_staff_help}
                owner_staff_json={ownerdata?.staff_json}
                owner_bol_admin_help={ownerdata?.bol_admin_help}
                placeholder={placeholder}
                navtype={navtype}
                helptype={helptype}
                startPlayer={()=>{}}
                isProfileHeader={false}
            />
        </div>)
    

}